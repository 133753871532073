<template>
    <span>
        <v-row class=" service-main-wrapper">
            <!-- Start Single Service  -->
            <v-col
                lg="6"
                md="4"
                sm="6"
                cols="12"
                v-for="(service, i) in $store.state.servicios"
                :key="i"
            >
                <div class="service service__style--2 text-left bg-gray">
                    <v-btn
                        v-if="$store.state.admin.Admin == true"
                        style="position:absolute;right:10px;top:10px;"
                        text
                        icon
                        color="red lighten-2"
                        @click="OpenDialog(i)"
                    >
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>
                    <a >
                        <div class="service">
                            <div class="icon little-icon">
                                <v-img :src="service.ICON" />
                                <!-- <div v-html="iconSvg(service.ICON)"></div> -->
                            </div>
                            <div class="content">
                                <h3 class="heading-title">
                                    {{ service.TITULO }}
                                </h3>
                                <p>
                                    {{ service.SUBTITULO }}
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            </v-col>
            <!-- End Single Service  -->
        </v-row>

        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Editar
                </v-card-title>

                <v-card-text class="mt-5">
                    <label for="Icon" class="d-flex">
                        <img :src="srv.ICON" alt="" srcset="" />
                        <input
                            type="file"
                            name=""
                            id="Icon"
                            style="display: none"
                            @change="(e) => preview(e)"
                        />
                    </label>
                    <v-text-field
                        name="name"
                        label="Titulo"
                        v-model="srv.TITULO"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        name="name"
                        label="Subtitulo"
                        v-model="srv.SUBTITULO"
                        outlined
                    ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="
                            $store.dispatch('update_Servicio', srv);
                            dialog = false;
                        "
                    >
                        Actualizar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import feather from "feather-icons";
export default {
    data() {
        return {
            dialog: false,
            srv: Object,

            serviceContent: [
                {
                    icon: "cast",
                    title: "Business Stratagy",
                    desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
                },
                {
                    icon: "layers",
                    title: "Website Development",
                    desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
                },
                {
                    icon: "users",
                    title: "Marketing & Reporting",
                    desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
                },
                {
                    icon: "monitor",
                    title: "Mobile Development",
                    desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
                },
                {
                    icon: "users",
                    title: "Marketing & Reporting",
                    desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
                },
                {
                    icon: "monitor",
                    title: "Mobile Development",
                    desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
                },
            ],
        };
    },
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg();
        },
        OpenDialog(index) {
            this.dialog = true;

            this.srv = this.$store.state.servicios[index];
        },
        preview(e) {
            if (e.target.files.length > 0) {
                let _this = this;
                var reader = new FileReader();
                reader.onload = function() {
                    // var output = document.getElementById(id);
                    // output.src = reader.result;

                    _this.srv.ICON = reader.result;
                };
                reader.readAsDataURL(e.target.files[0]);
            } else {
                return;
            }
        },
    },
    created() {
        this.$store.dispatch(
            "get_Servicios",
            this.$store.state.iglesia.IglesiaID
        );
    },
};
</script>

<style lang="scss" scoped>
.little-icon {
    width: 60px;
}
</style>
